.proposals-card-delails {
    color: black !important;
    font-size: 0.875rem !important;
}
.proposals-card-title {
    font-size: 25px;
    text-align: center;
}
.proposals-card-btn {
    width: 100px;
    font-size: 12px !important;
}
.proposals-card-footer-block {
    display: flex;
    justify-content: space-between;
}
.proposals-card-msg {
    font-size: 0.875rem !important;
    color: #3C4858 !important;
}
.v-j-variable {
    text-align: justify;
    color: #555;
    font-size: 0.963rem;
    font-weight: 800 !important;
    border-top-width: 0 !important;
    border-bottom-width: 1px;
    white-space: normal;
}
.v-j-variable-text {
    color: #3C4858 !important;
    padding: 12px 8px;
    position: relative;
    font-size: 0.875rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    white-space: normal;
}

@media screen and (max-width: 500px) {
    .v-j-variable{
        text-align: center;
    }
    .v-j-variable-text {
        display: block;
        padding: 5px 0 ;
        max-width: 100%;
    }
}

.v-j-variable-text2 {
    color: #3C4858 !important;
    position: relative;
    font-size: 0.875rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
}
.cart-jobs {
    max-width: 900px !important;
    margin: 0 auto;
}
.react-show-more {
    display: block;
}
.offer-hire-text {
    text-align: left;
    display: flex;
    align-items: center;
}
.media-hire {
    margin-bottom: 20px !important;
}

.pagination-container-hire {

}

.pagination-container-hire .pagination {
    /*min-width: 696px;*/
    /*list-style: none;*/
    /*padding-top: 20px;*/
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0px;
}

.pagination-container-hire .pagination li {
    display: inline;
    padding-right: 8px;
    margin-top: 1rem;
}
.pagination-container-hire a {
    color: #999;
}
.pagination .first-pagin a {
    display: none;
    min-width: 80px;
}
.pagination .last-pagin a {
    display: none;
    min-width: 70px;
}
.pagination {
    font-size: 12px;
}
.pagination .active a {
    color: #FFF;
    display: inline-flex;
    background-color: #4caf50;
    border-radius: 20px;
    padding: 5px;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
}

.pagination .active a:hover {
    color: #FFF;
    background-color: #4caf50;
}

.pagination  a {
    border-radius: 20px;
    padding: 5px;
    display: inline-flex;
    min-width: 30px;
    width: 30px;
    height: 30px;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}

.pagination a:hover {
    color: #999;
    display: inline-flex;
    border-radius: 20px;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    background-color: #eee;
}

.pagination .prev-pagin a {
    width: 85px !important;
}

.pagination .prev-pagin a:hover {
    color: #999;
    display: inline-flex;
    border-radius: 20px;
    width: 85px;
    height: 30px;
    justify-content: center;
    align-items: center;
    background-color: #eee;
}

.pagination .next-pagin a {
    width: 50px !important;
}
.pagination .next-pagin a:hover {
    color: #999;
    display: inline-flex;
    border-radius: 20px;
    width: 50px;
    height: 30px;
    justify-content: center;
    align-items: center;
    background-color: #eee;
}
.all-jobs-padding {
    padding-left: 0px;
}
.all-jobs-name {
    padding-bottom: 5px;
    font-weight: bold;
    color: black !important;
}
.all-jobs-height {
     font-size: 1.5625rem !important;
     line-height: 1.4em !important;
}
.pagination-all-jobs {
    display: flex;
    list-style: none;
    padding-left: 0;
    border-radius: 0.25rem;
}
.job-title {
    font-size: 0.963rem;
}
.btn-search {
    width: 40px !important;
    height: 40px !important;
    margin-right: 4px !important;
}
.input-search-jobs {
    height: 40px;
    width: 100%;
    padding-left: 10px;
    border: 0px;
}
.div-search {
    display: inline-flex !important;
    align-items: center !important;
    width: 100%;
    padding-left: 0px !important;
}
.label-input-search {
    width: 100%;
}
.form-search {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0px !important;
}
.form-search-cart {
    height: 50px;
}
.title-edit {
    margin-left: 16px !important;
}
.div-search div {
    margin-left: 16px !important;
    margin-bottom: 0px !important;
}
.job-page-title {
    font-size: 20px;
    white-space: normal;
}
.btn-job-page {
    display: flex;
    flex-direction: row-reverse;
}
.job-page-first {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
@media screen and (max-width: 500px) {
    .job-page-first {
        display: block;
    }
}
.job-page-btn-and-proposol {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
@media (max-width: 500px) {
    .job-page-btn-and-proposol {
        justify-content: center;
    }
}
.img-page-job {
    margin-bottom: -7px;
}
a.job-link:hover {
    color: #008329 !important;
    text-decoration: underline;
}

@media screen and (max-width: 400px) {
    .btn-center-job {
        /*margin: 0 auto !important;*/
    }
}
a.react-show-more:hover {
    text-decoration: underline;
}

@media (max-width: 830px) {
    .m-header {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .m-cont {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

@media (max-width: 830px) {
    .job-detail-proposal {
        flex-direction: column;
    }
    .line {
        border-left: 0px solid #ccc !important;
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
    .job-detail-proposal-descr {
        width: 100% !important;
    }
}

.small-preloader {
    top: 42% !important;
    left: 0 !important;
    width: 120px !important;
    right: 0 !important;
    height: 120px !important;
    margin: auto;
    position: absolute;
    background: none !important;
    width: 120px !important;
}

.small-preloader-svg {
    color: #4caf50 !important;
}
.job-detail-proposal {
    display: flex;
}
.job-detail-proposal-descr {
    width: 79%;
}
.desc-no-padding {
    padding-left: 0px;
}
.line {
    border-left: 2px solid #ccc;
    margin-left: 20px;
    padding-left: 10px;
}

.view-job-posting {
    color: #4caf50;
    font-weight: 200;
}
.view-job-posting:hover {
    text-decoration: underline;
}

.accordion-title {
    color:  #4caf50 !important;
    font-size: 14px !important;
    font-weight: 200 !important;
}
.accordion-title:hover {
    text-decoration: underline !important;
}
.accardion-border {
    border-bottom: 0px solid #ddd !important;
}

.content-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.additional-textarea {
    width: 100%;
    height: 250px;
    border: 1px solid #E0E0E0;
    padding: 15px;
}
.additional-textarea-approve-pay {
    width: 100%;
    height: 150px;
    border: 1px solid #E0E0E0;
    padding: 15px;
    resize:none;
}
.submit-work-textarea {
    width: 100%;
    height: 110px;
    border: 1px solid #E0E0E0;
    padding: 15px;
    resize:none;
}
.file-dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px dashed #4caf50;
    height: 60px;
    margin: 15px 0 20px 0;
}
.upload-span {
    color: #4caf50;
    font-weight: bold;
}
.btn-drag-submit {
    margin-right: 10px !important;
}

@media screen and (max-width: 400px) {
    .btn-additional-details {
        display: flex;
        justify-content: center;
    }
    .btn-drag-submit {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .btn-drag-reset {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.table-contracts {
    width: 100%;
    display: table;
    border-spacing: 2px;
    border-color: grey;
    border-collapse: collapse;
}
.table-tr {
    height: auto;
    display: table-row;
    outline: none;
    vertical-align: middle;
    border-bottom: 1px solid #ddd;
}
.table-td {
    color: #3C4858;
    padding: 12px 8px!important;
    position: relative;
    font-size: 0.875rem;
    border-top: 1px solid #ddd;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.5em;
    white-space: nowrap;
    border-bottom: none;
    vertical-align: middle;
    height: 64px !important;
    /*text-align: center !important;*/

}

.table-th {
    padding: 12px 8px!important;
    /*text-align: center !important;*/
}
.table-div {
    overflow-x: auto;
}

.title-pages {
    padding-left: 15px;
}
.job-fix-wiight {
    font-weight: 400 !important;
}
.empty-msg {
    padding-left: 15px;
}
.client-name-contracts {
    color: #3C4858;
}

.job-client-font {
    color: black;
    font-weight: 400;
}
.info-contractor {
    font-weight: 100 !important;
    color:  #3C4858 !important;
}

.pay-pal-btn {
    width: 200px;
    background: #ffc439 !important;
    color: #1a237e !important;
}
.btn-finalize{
    width: 150px !important;
    padding: 10px !important;
    color: #00c853;
}

.btn-submit-work {
    width: 120px !important;
    padding: 10px !important;
    color: #00c853;
}
.btn-submit-work-client {
    width: 155px !important;
    padding: 10px !important;
    color: #00c853;
}
.btn-center-job {
    width: 120px !important;
    padding: 10px !important;
}

.tb-submit-work {
    width: 160px;
}

.v-j-variable-center {
    text-align: center !important;
    white-space: normal;

}
.job-pay-title {
    display: flex;
    justify-content: center;
}
.job-pay-details  {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.paypal-logo {
    font-family: "PayPal-Sans", "HelveticaNeue", sans-serif;;
    font-weight: 925;
    font-size: 22px;
}

.paypal-logo-mini{
    font-family: "PayPal-Sans", "HelveticaNeue", sans-serif;;
    font-weight: 925;
}

.paypal-powerd-by{
    text-align: center;
    margin-top: '10px'    
}

.paypal-logo-first {    
    color: #253b80;
}

.paypal-logo-last {    
    color: #179bd7;
}


.paypal-button {
    padding: 8px 20px;
    border: 0px solid #ffc439;
    border-radius: 5px;
    background:  #ffc439;
    margin: 0 auto;
    display: block;    
    width: 300px !important; 
    height: 45px !important;
    position: relative;
}
.paypal-button:hover {
    cursor: pointer;
}

.verifide-color {
    color: #4caf50 !important;
}

.text-complete-work {
    /*width: 380px;*/
    text-align: center;
}
.complete-work-square {
    display: flex;
}
@media (max-width: 830px) {
    .complete-work-square {
        flex-direction: column;
        align-items: center;
    }
}

.square-meters {
    height: 28px;
    width: 80px;
    margin-left: 20px;
    padding-left: 5px;
    text-align: center;
}
.status-work-style {
    text-align: center;
    max-width: 100%;
}
.block-over-work {
    display: flex;
    padding-bottom: 10px;
}
.div-overtime {
    padding-top: 11px;
}
.div-comment {
    text-align: center;
}
.approval-comment {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

.approval-font-100 {
    font-weight: 100 !important;
}
.approval-font-400 {
    font-weight: 400 !important;
}

.dv-star-rating label{
    font-size: 28px !important;
}

.btn-job-submit-work {
    display: flex;
    justify-content: center;
}

